import { Box } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import React from "react"
import ReactHtmlParser from "react-html-parser"
import "react-multi-carousel/lib/styles.css"
import { useSelector } from "react-redux"
import { graphql } from "gatsby"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Textfield from "../components/TextField"
import { SEO } from "../components/SEO"

export const query = () => graphql`
  query($locale: String!) {
    allContentfulAboutPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          officeSectionTitle
          officesReferences {
            officeName
            officeEmail
            officeNumber
            officeAddress
            officePostalCode
          }
          queryTopics
          queriesSectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulLandingPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          hateContactFormTitle
          hateContactFormSubTitle
          hateContactFormLogos {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          hateContactFormLogosId
          queryDescription {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }

    allContentfulContactPageSeo(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          seoTitle
          seoDescription
        }
      }
    }
  }
`

const BlogScreen = ({
  location,
  pageContext,
  data: { allContentfulAboutPageModel, allContentfulLandingPageModel, allContentfulContactPageSeo },
}) => {
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const contactPageInfo = allContentfulAboutPageModel.edges[0].node
  const { seoTitle, seoDescription } = allContentfulContactPageSeo.edges[0].node

  const {
    hateContactFormTitle,
    hateContactFormSubTitle,
    hateContactFormLogos,
    hateContactFormLogosId,
    queryDescription,
  } = allContentfulLandingPageModel.edges[0].node
  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO
        title={seoTitle}
        description={seoDescription}
      />
      <Box mt={10} className={locale === "zh" && "chinese-version"}>
        <Textfield
          backgroundColor={"black"}
          image={contactPageInfo.queriesSectionImage.fluid}
          queryDescription={ReactHtmlParser(
            queryDescription?.childMarkdownRemark?.html
              ?.split("\n")
              ?.join("<br/>")
          )}
          queryTopics={contactPageInfo.queryTopics}
          hateContactFormTitle={hateContactFormTitle}
          hateContactFormSubTitle={hateContactFormSubTitle}
          hateContactFormLogos={hateContactFormLogos}
          hateContactFormLogosId={hateContactFormLogosId}
          locale={pageContext.locale}
        />
      </Box>
      <Contact
        title={"Other Contacts"}
        officesData={contactPageInfo.officesReferences}
      />
      <Divider light style={{ marginTop: 120 }} />
      <Grid container justify="center" style={{ marginTop: 120 }}>
        <Grid xs={11} sm={10} item>
          <Footer />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default BlogScreen
